<template>
  <Sidebar ref="sidebar" :title="title">
    <form @submit.prevent="save" class="pb-20">

      <Overlay v-if="loading" class="z-2 mt-16 flex justify-center items-center bg-white">
        <div class="bg-white shadow rounded w-8 h-8 flex justify-center items-center text-prasset-green-500">
          <LoadingIndicator />
        </div>
      </Overlay>

      <div class="relative z-1">
        <FormsFields
          v-if="entryType === 'forms'"
          mode="create"
          :validationErrors="errors"
          :form="form"
        />
        <FormNotificationsFields
          v-else-if="entryType === 'formnotifications'"
          mode="create"
          :validationErrors="errors"
          :form="form"
        />
        <FieldsGenerator
          v-else
          :errors="errors"
          :fields="fields"
          :data="form"
          :entryId="form.id"
          :entryType="entryType"
          :fileCollection="form.media"
        />
      </div>

      <div class="w-full bg-white border-t border-gray-300 absolute bottom-0 inset-x-0 px-5 py-4 z-20">
        <div class="flex">
          <button class="button button--outlined mr-4" type="button" @click="$refs.sidebar.close()">
            {{ $tuf('cancel') }}
          </button>

          <button
            type="submit"
            class="button button--opague relative"
            :class="{ 'opacity-25': loading && submittedBy !== 'save-and-back' }"
            :disabled="loading"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save-and-back' }">
              {{ $tuf('save_and_back') }}
            </span>
            <span v-if="loading && submittedBy === 'save-and-back'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>
        </div>
      </div>
    </form>
  </Sidebar>
</template>

<script>
import { toRefs, reactive, onMounted } from '@vue/composition-api';
import { generateUUID } from '@/providers/helpers';
import Api from '@/providers/api';
import EventBus from '@/eventbus';
import FieldsGenerator from '@/modules/core/views/components/FieldsGenerator';
import FormFields from '@/fieldsets/form/formfields';
import LoadingIndicator from '@/components/LoadingIndicator';
import Overlay from '@/components/Overlay';
import Sidebar from '@/modules/core/views/components/Sidebar';
import FormNotificationsFields from '@/modules/form/views/templates/FormNotificationsFields';
import FormsFields from '@/modules/form/views/templates/FormsFields';
import useChangedState from '@/compositions/useChangedState';

export default {
  name: 'Create',

  components: {
    FieldsGenerator,
    LoadingIndicator,
    Overlay,
    Sidebar,
    FormNotificationsFields,
    FormsFields,
  },

  setup(props, { root, refs }) {
    const { project_id, type } = root.$route.params;
    const { changed, watchChanges, commitChanges } = useChangedState();

    const state = reactive({
      form: {
        id: generateUUID(),
      },
      errors: {},
      ready: false,
      changed,
      loading: false,
      submittedBy: null,
    });

    const labels = {
      forms: root.$tuf('new_form'),
      formnotifications: root.$tuf('new_notification'),
    };

    /**
     * Load form data.
     */
    async function load() {
      state.loading = false;
      state.ready = true;

      watchChanges(state, 'form');
    }

    /**
     * Save form data.
     */
    async function save(close = true, submittedBy = 'save-and-back') {
      state.submittedBy = submittedBy;
      state.loading = true;
      state.errors = {};

      try {
        await Api().post(`projects/${project_id}/${type}`, {
          ...state.form,
        });

        root.$notify({ type: 'success', title: 'Succes', text: `${labels[type]} is aangemaakt` });

        state.loading = false;

        commitChanges(state, 'form');

        // Notify table to update the records.
        EventBus.$emit('record:updated');

        if (close) {
          refs.sidebar.close();
        }
      } catch (error) {
        state.loading = false;
        state.errors = error.response.data.errors;
      }
    }

    onMounted(() => {
      load();
    });

    return {
      ...toRefs(state),
      save,
      fields: FormFields[type],
      entryType: type,
      title: labels[type],
    };
  },
};
</script>
